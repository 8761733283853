/* Variable Declarations */
:root {
    --strokeWidth: 2px;
    --strokeColor: black;
    --timing: 2.6s;
    /* --easing: ease-in-out; */
    --easing: cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  
  
  /* Scroll Down Animation */
  .scroll-down-dude:before,
  .scroll-down-dude:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-bottom: var(--strokeWidth) solid var(--strokeColor);
    border-right: var(--strokeWidth) solid var(--strokeColor);
  }
  
  .scroll-down-dude:before {
    animation: down-arrow-before var(--timing) var(--easing) infinite;
  }
  
  .scroll-down-dude:after {
    animation: down-arrow-after var(--timing) var(--easing) infinite;
  }
  
  /* Keyframes */
  @keyframes down-arrow-before {
    50% {
      transform: rotate(45deg) translate(70%, 70%);
    }
    100% {
      transform: rotate(45deg) translate(70%, 70%);
    }
  }
  
  @keyframes down-arrow-after {
    50% {
      transform: rotate(45deg) translate(110%, 110%);
      opacity: 0;
    }
    51% {
      transform: rotate(45deg) translate(-130%, -130%);
    }
    100% {
      transform: rotate(45deg) translate(-70%, -70%);
      opacity: 1;
    }
  }
  @media (min-width: 2560px) {
    .start-text {
      font-size: 48px;
      /* color: blue; */
    }
  }
  @media (min-width: 1140px) and (max-width: 2560px) {
    .start-text {
      font-size: 48px;
      /* color: blue; */
    }
  }
  @media (min-width: 1024px) and (max-width: 1140px) {
    .start-text {
      font-size: 48px;
      /* color: blue; */
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .start-text {
      font-size: 44px;
      /* color: blue; */
    }
  }
  
  @media (min-width: 425px) and (max-width: 767px) {  /* Adjusted max-width to 767px to avoid overlap */
    .start-text {
      font-size: 34px;
      /* color: blue; */
    }
  }
  
  @media (min-width: 375px) and (max-width: 424px) {
    .start-text {
      font-size: 34px;
      /* color: blue; */
    }
  }
  
  @media (min-width: 320px) and (max-width: 374px) {
    .start-text {
      font-size: 33px;
      /* color: blue; */
    }
  }
  
  @media (max-width: 319px) {
    .start-text {
      font-size: 21px;
      /* color: blue; */
    }
  }
  
  /* html, body, section {
    block-size: 100%;
  }
  
  html {
    scroll-snap-type: y mandatory;
  }
  
  section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  body {
    margin: 0;
    font-family: system-ui, sans-serif;
    font-size: 4rem;
  } */