.sl-bubble2{
  /* position: absolute; */
/* left: 150px; */
width: 100px;
/* top: 70px; */
margin-top: 10px !important;
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: -9px 0;
}