body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  padding: 10px 100px;
}

.why-importance {
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .container {
    padding: 10px;
  }
  .why-importance {
    font-size: 20px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .why-importance {
    font-size: 20px;
    font-weight: 600;
  }
}

@media (max-width: 375px) {
  .container {
    padding: 10px;
  }

  .why-importance {
    font-size: 20px;
    font-weight: 600;
  }
}

@media (max-width: 375px) {
  .container {
    padding: 10px;
  }

  .why-importance {
    font-size: 20px;
    font-weight: 600;
  }
}

@media (max-width: 320px) {
  .container {
    padding: 10px;
  }
  .why-importance {
    font-size: 20px;
    font-weight: 600;
  }
}
